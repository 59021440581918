.async-hide {
  opacity: 0 !important
}

.editorContainer {
  border-bottom: 1px solid transparent;
}

.editorContainer::before {
 
}

.editorContainer.hasFocus {
  border-bottom: 1px solid #63a4ff;
  transition: 0.2s border-bottom ease-out;
}

.editorContainer.error {
  border-bottom: 1px solid red;
  transition: 0.2s border-bottom ease-out;
}


.DraftEditor-root{
  width: 100%;
}

.DraftEditor-editorContainer {
  /* border-left: 20px solid #000; */
}

.public-DraftEditorPlaceholder-inner {
  width: 100%;
}

.BrainhubCarousel__track {
  border-radius: 4px;
}

.BrainhubCarouselItem {
  margin-right: 8px;
}

/* div.DraftEditor-root {
  border-bottom: 1px solid transparent;
}

div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  height: 100%;
}  */